import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const AboutDetailsWrapper = styled.section`
  position: relative;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media screen and (max-width: 568px) {
      flex-direction: column;
    }

    .image {
      width: 405px;
      margin-right: 30px;
      @media screen and (max-width: 568px) {
        width: 100%;
        margin-bottom: 70px;
        margin-right: 0px;
      }
    }
  }

  .container-alt {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 132px auto 110px;
    @media screen and (max-width: 990px) {
      margin: 70px auto 80px;
    }
    @media screen and (max-width: 568px) {
      flex-direction: column-reverse;
      margin: 30px auto 50px;
    }

    .image {
      width: 405px;
      margin-left: 30px;
      @media screen and (max-width: 568px) {
        width: 100%;
        margin-bottom: 70px;
        margin-left: 0px;
      }
    }
  }

  .content {
    margin-top: 20px;
    max-width: 540px;
    h2 {
      font-size: ${themeGet("fontSizes.13")}px;
      line-height: 50px;
      margin-bottom: 20px;
      @media screen and (max-width: 990px) {
        font-size: ${themeGet("fontSizes.10")}px;
        line-height: 36px;
        margin-bottom: 10px;
      }
      @media screen and (max-width: 568px) {
        text-align: center;
        margin-top: -55px;
      }
    }
    .description {
      max-width: 450px;
      margin-bottom: 20px;
      line-height: 28px;
      font-size: ${themeGet("fontSizes.5")}px;

      @media screen and (max-width: 990px) {
        font-size: ${themeGet("fontSizes.2")}px;
        line-height: 20px;
        margin-bottom: 16px;
      }
      @media screen and (max-width: 568px) {
        text-align: center;
      }
    }
  }

  .content-alt {
    margin-top: 20px;
    max-width: 500px;
    h2 {
      font-size: ${themeGet("fontSizes.13")}px;
      line-height: 50px;
      margin-bottom: 20px;
      @media screen and (max-width: 990px) {
        font-size: ${themeGet("fontSizes.10")}px;
        line-height: 36px;
        margin-bottom: 10px;
      }
      @media screen and (max-width: 568px) {
        text-align: center;
        margin-top: -55px;
      }
    }
    .description {
      max-width: 450px;
      line-height: 28px;
      font-size: ${themeGet("fontSizes.5")}px;
      @media screen and (max-width: 990px) {
        font-size: ${themeGet("fontSizes.2")}px;
        line-height: 20px;
      }
      @media screen and (max-width: 568px) {
        text-align: center;
        max-width: 100%;
      }
    }
  }
`;
