import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const SectionWrapper = styled.section`
  padding-top: 140px;
  padding-bottom: 140px;
  overflow: hidden;
  @media only screen and (max-width: 990px) {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  @media only screen and (max-width: 568px) {
    padding-top: 78px;
    padding-bottom: 78px;
  }
`;

export const TeamWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  @media screen and (max-width: 990px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media screen and (max-width: 670px) {
    grid-template-columns: 1fr;
    gap: 34px;
  }

  .team-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .team-image {
    width: 245px;
    @media screen and (max-width: 1084px) {
      width: 200px;
    }
    @media screen and (max-width: 900px) {
      width: 150px;
    }
    @media screen and (max-width: 670px) {
      width: 350px;
    }
    @media screen and (max-width: 568px) {
      width: 245px;
    }
  }

  .team-info {
    margin-top: 40px;
    @media screen and (max-width: 990px) {
      margin-top: 30px;
    }
    @media screen and (max-width: 568px) {
      margin-top: 28px;
    }
  }

  .team-name {
    font-family: "Poppins";
    font-size: ${themeGet("fontSizes.6")}px;
    text-align: center;
    font-weight: 600;
    @media screen and (max-width: 900px) {
      font-size: ${themeGet("fontSizes.4")}px;
    }
    @media screen and (max-width: 568px) {
      font-size: ${themeGet("fontSizes.6")}px;
    }
  }

  .team-position {
    font-weight: 500;
    color: #77797c;
  }
`;

export default SectionWrapper;
