import Container from "common/components/UI/Container";
import GatsbyImage from "common/components/GatsbyImage";
import React from "react";
import SectionHeader from "common/components/SectionHeading";
import Text from "common/components/Text";
import { graphql, useStaticQuery } from "gatsby";

import SectionWrapper, { TeamWrapper } from "./team.style";

const Team = () => {
  const data = useStaticQuery(graphql`
    query {
      ertCreditJson {
        ABOUT_TEAM {
          title
          subtitle
          text
          team {
            name
            position
            bioURL
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  quality: 100
                )
              }
            }
          }
        }
      }
    }
  `);
  const { title, subtitle, text, team } = data.ertCreditJson.ABOUT_TEAM;

  return (
    <SectionWrapper>
      <Container width="1420px">
        <SectionHeader
          title={title}
          subtitle={subtitle}
          color="#69A2B1"
          text={text}
        />
        <TeamWrapper>
          {team.map((team, index) => {
            return (
              <a href={team.bioURL}>
                <div key={index} className="team-wrapper">
                  <div className="team-image">
                    <GatsbyImage
                      src={
                        (team.image !== null) | undefined
                          ? team.image.childImageSharp.gatsbyImageData
                          : {}
                      }
                      alt="Team Member"
                    />
                  </div>
                  <div className="team-info">
                    <Text content={team.name} className="team-name" />
                    <Text content={team.position} className="team-position" />
                  </div>
                </div>
              </a>
            );
          })}
        </TeamWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Team;
