import Container from "common/components/UI/Container";
import GatsbyImage from "common/components/GatsbyImage";
import Heading from "common/components/Heading";
import React from "react";
import Text from "common/components/Text";
import { graphql, useStaticQuery } from "gatsby";
import { useWindowSize } from "common/hooks/useWindowSize";

import SectionWrapper from "./trustedBy.style";

const TrustedBy = () => {
  const { width } = useWindowSize();

  const data = useStaticQuery(graphql`
    query {
      ertCreditJson {
        PARTNERSHIPS_TRUSTED {
          title
          subtitle
          imageBg {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                quality: 100
              )
            }
          }
          imageBgMobile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                quality: 100
              )
            }
          }
        }
      }
    }
  `);
  const { title, subtitle, imageBg, imageBgMobile } =
    data.ertCreditJson.PARTNERSHIPS_TRUSTED;

  return (
    <>
      <SectionWrapper>
        <Container width="1460px">
          {width > 568 ? (
            <GatsbyImage
              src={
                (imageBg !== null) | undefined
                  ? imageBg.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="Hero Background"
            />
          ) : (
            <GatsbyImage
              src={
                (imageBgMobile !== null) | undefined
                  ? imageBgMobile.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="Hero Background"
            />
          )}
          <div className="content-wrapper">
            <Heading as="h2" content={title} />
            <Text content={subtitle} className="text" />
          </div>
        </Container>
      </SectionWrapper>
    </>
  );
};

export default TrustedBy;
