import Box from "common/components/Box";
import Container from "common/components/UI/Container";
import GatsbyImage from "common/components/GatsbyImage";
import Heading from "common/components/Heading";
import React from "react";
import Text from "common/components/Text";
import { graphql, useStaticQuery } from "gatsby";

import { AboutDetailsWrapper } from "./aboutDetails.style";

const AboutDetails = () => {
  const data = useStaticQuery(graphql`
    query {
      ertCreditJson {
        ABOUT_DETAILS {
          items {
            title
            text
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  quality: 100
                )
              }
            }
          }
        }
      }
    }
  `);

  const { items } = data.ertCreditJson.ABOUT_DETAILS;

  return (
    <AboutDetailsWrapper>
      <Container width="1120px">
        <Box className="image">
          <GatsbyImage
            src={
              (items[0].image !== null) | undefined
                ? items[0].image.childImageSharp.gatsbyImageData
                : {}
            }
            alt="About us Details"
          />
        </Box>
        <Box className="content">
          <Heading as="h2" content={items[0].title} />
          <Text
            as="p"
            content="We believe almost every small to medium size organization in the USA can claim the Employee Retention Credit (ERC)."
            className="description"
          />
          <Text
            as="p"
            content="We designed and built a system that allows us to compute the ERC for any organization with fewer than 500 employees within 48 hours."
            className="description"
          />
          <Text
            as="p"
            content="At ERT.credit, you have a team of dedicated payroll tax specialists and technologists with over 40 years of combined experience building and constantly refining automated systems that maximize benefits for you."
            className="description"
          />
        </Box>
      </Container>

      <Container width="1120px" className="container-alt">
        <Box className="content-alt">
          <Heading as="h2" content={items[1].title} />
          <Text as="p" content={items[1].text} className="description" />
        </Box>
        <Box className="image">
          <GatsbyImage
            src={
              (items[1].image !== null) | undefined
                ? items[1].image.childImageSharp.gatsbyImageData
                : {}
            }
            alt="About us Details"
          />
        </Box>
      </Container>

      <Container width="1120px">
        <Box className="image">
          <GatsbyImage
            src={
              (items[2].image !== null) | undefined
                ? items[2].image.childImageSharp.gatsbyImageData
                : {}
            }
            alt="About us Details"
          />
        </Box>
        <Box className="content">
          <Heading as="h2" content={items[2].title} />
          <Text as="p" content={items[2].text} className="description" />
        </Box>
      </Container>
    </AboutDetailsWrapper>
  );
};

export default AboutDetails;
