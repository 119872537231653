import * as React from "react";
import AboutDetails from "containers/AboutDetails";
import HeroAlt from "containers/HeroAlt";
import Layout from "common/components/Layout";
import Seo from "components/seo";
import Team from "containers/Team";
import Trusted from "containers/TrustedBy";

const AboutUsPage = ({ location }) => {
  return (
    <Layout location={location.pathname}>
      <Seo title="About Us" />
      <HeroAlt
        title="About Us"
        text="We are founded by an expert team of payroll and tax specialists coupled with technology leaders who are on a mission to make it very simple for businesses to claim their Employee Retention Credit."
      />
      <AboutDetails />
      <Team />
      <Trusted />
    </Layout>
  );
};

export default AboutUsPage;
