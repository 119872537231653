import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const SectionWrapper = styled.section`
  padding-bottom: 125px;
  @media screen and (max-width: 990px) {
    padding-bottom: 90px;
  }
  @media screen and (max-width: 568px) {
    padding-bottom: 80px;
  }

  .container {
    position: relative;
  }

  .content-wrapper {
    position: absolute;
    left: 50%;
    top: 110px;
    transform: translate(-50%, 0%);
    width: 625px;
    @media screen and (max-width: 990px) {
      width: 525px;
    }
    @media screen and (max-width: 760px) {
      top: 50px;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      top: 30px;
    }

    h2 {
      text-align: center;
      font-size: ${themeGet("fontSizes.13")}px;
      line-height: 50px;
      margin-bottom: 20px;
      @media screen and (max-width: 990px) {
        font-size: ${themeGet("fontSizes.10")}px;
        line-height: 36px;
        margin-bottom: 16px;
      }
      @media screen and (max-width: 568px) {
        margin-bottom: 10px;
        max-width: 300px;
        margin: 0 auto;
      }
    }

    .text {
      text-align: center;
      font-size: ${themeGet("fontSizes.5")}px;
      line-height: 28px;
      font-weight: 400;
      color: ${themeGet("colors.text")};
      @media screen and (max-width: 990px) {
        font-size: ${themeGet("fontSizes.2")}px;
        line-height: 20px;
        max-width: 500px;
      }
      @media screen and (max-width: 568px) {
        font-size: ${themeGet("fontSizes.2")}px;
        line-height: 20px;
      }
    }
  }
`;

export default SectionWrapper;
